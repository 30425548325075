import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "lucide-react";
//eslint-disable-next-line
import Step1 from "../HowToTerp/Step-1.png";
//eslint-disable-next-line
import Step2 from "../HowToTerp/Step-2.png";
//eslint-disable-next-line
import Step3 from "../HowToTerp/Step-3.png";
//eslint-disable-next-line
import Step4 from "../HowToTerp/Step-4.png";


const HowTo = () => {
    let nav = useNavigate();
    return (
        <main className="w-11/12 md:w-1/2 mx-auto bg-[#e9fbee] my-6 min-h-full rounded-lg text-left">
            <button onClick={() => nav(-1)} className="rounded-full h-1/4 m-10 mb-0 text-green-600 font-semibold border-2 border-green-600">
                <ArrowLeft size={32} />
            </button>
            <div className="flex flex-col justify-center text-left w-full mb-6 p-8">
                <h1 className="text-4xl font-bold text-black mb-8 mx-auto uppercase">How To Terp</h1>

                {/* <h2 className="text-2xl font-bold text-black my-4 uppercase">Step 1</h2> */}
                <img
                    src={Step1}
                    alt="Step 1"
                    className="w-full md:w-3/4 mx-auto mb-4 object-cover"
                />
                {/* <h2 className="text-2xl font-bold text-black my-4 uppercase">Step 2</h2> */}
                <img
                    src={Step2}
                    alt="Step 2"
                    className="w-full md:w-3/4 mx-auto mb-4 object-cover"
                />
                {/* <h2 className="text-2xl font-bold text-black my-4 uppercase">Step 3</h2> */}
                <img

                    src={Step3}
                    alt="Step 3"
                    className="w-full md:w-3/4 mx-auto mb-4 object-cover"
                />

                {/* <h2 className="text-2xl font-bold text-black my-4 uppercase">Step 4</h2> */}
                <img

                    src={Step4}
                    alt="Step 4"
                    className="w-full md:w-3/4 mx-auto mb-4 object-cover"
                />

            </div>
        </main>
    );
}

export default HowTo;