import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import profilePicture from './profile.png';
import { Send, XCircle, Phone, MessageCircle, Mail } from 'lucide-react'


const Links = () => {

  const [showModal, setShowModal] = useState(false);

  const links = [
    {
      title: 'Buy A TerpScoop 🛍️',
      url: 'https://shop.terpmetrix.com',
    },
    {
      title: 'Terpscoops Instagram 🥄',
      url: 'https://instagram.com/terpscoops',
    },
    {
      title: 'Terpmetrix Home 🏠',
      url: 'https://terpmetrix.com',
    },
    {
      title: 'TerpTracker (Alpha) 🔍',
      url: 'https://terptracker.co',
    },
  ];

  const socialLinks = [
    {
      icon: faInstagram,
      url: 'https://instagram.com/terpmetr.x',
    },
    {
      icon: faTwitter,
      url: 'https://twitter.com/terpmetrix',
    },
    {
      icon: faLinkedin,
      url: 'https://www.linkedin.com/company/terpmetrix',
    },
  ];

  return (
    <>
      {showModal && (
        <div className="absolute min-h-full min-w-full bg-slate-500/50 z-10 justify-center items-center p-10">
          <div className="flex flex-col max-w-lg bg-white drop-shadow-xl border-green-600 mt-44 m-auto rounded-lg p-4 pb-8 text-center text-slate-800">
            <button onClick={() => setShowModal(false)} className='text-green-600'><XCircle /></button>
            <p className='text-xl text-slate-800 font-bold'>Sam Steingard</p>
            <div className='flex flex-row space-x-4 m-auto pt-4'>
            <a href='tel:3015254028'> <Phone className='text-green-600' size={32}/></a>
            <a href='sms:3015254028'> <MessageCircle className='text-green-600' size={32} /></a>
            <a href='mailto:sam@terpmetrix.com'> <Mail className='text-green-600' size={32} /></a>
            </div>
          </div>
        </div>
      )}
      <div className="min-h-full text-slate-800 flex flex-col items-center px-4 md:justify-center z-0">
        <div className="bg-slate-100 p-6 md:p-8 rounded-xl shadow-md w-full max-w-md mt-8 md:mt-4 items-center">
          <img
            src={profilePicture}
            alt="Profile"
            className="w-24 h-24 md:w-32 md:h-32 mx-auto mb-4 rounded-full object-cover"
          />
          <h1 className="text-xl md:text-2xl flex justify-center font-bold mb-2 text-slate-800">Terpmetrix</h1>
          <div className="flex justify-center mb-4">
            {socialLinks.map((link, index) => (
              <Link
                key={index}
                to={link.url}
                onClick={link.onClick ? (e) => { e.preventDefault(); link.onClick(); } : undefined}
                target={link.onClick ? undefined : "_blank"}
                rel="noreferrer"
                className="text-xl md:text-2xl mx-2 text-green-600 hover:text-green-800"
              >
                <FontAwesomeIcon icon={link.icon} />
              </Link>
            ))}
          </div>


          <button
            onClick={() => setShowModal(true)}
            className="rounded-full block w-1/2 h-1/4 mb-6 mx-auto text-center text-green-600 font-semibold border-2 border-green-600"
          >
            Get In Touch
          </button>


          {links.map((link, index) => (
            <a key={index} href={link.url}>
              <button
                key={index}
                target="_blank"
                rel="noopener noreferrer"
                className="rounded-full block w-full mb-4 p-4 text-center text-white font-semibold bg-green-600 hover:bg-green-800"
              >
                {link.title}
              </button>
            </a>
          ))}
          <div className="flex flex-col justify-center items-center w-full mb-6">
            <h2 className='text-lg font-bold italic text-slate-800 pt-6 pb-4'>Sign up for the newsletter!</h2>

            <form
              className="Form w-full justify-center items-center"
              name="mailing-list"
              method='POST'
            >
              <input type="hidden" name="form-name" value="mailing-list" />
              <div className="flex flex-row justify-center space-x-2">
                <label><input name="email" type="email" placeholder="Email address" className="bg-white rounded-full border-2 border-green-600 h-10 w-full max-w-xs p-2 pl-4" /></label>
                <button type="submit" className="border-0 rounded-full bg-green-600 h-10 w-10 text-center"><Send className='text-white ml-1.5 mt-1' /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>

  );
};

export default Links;
